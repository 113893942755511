import React, { useState } from 'react';
import Helmet from 'react-helmet';

import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import Copy, { Bold } from '@nib-components/copy';
import { PrimaryButton } from '@nib-components/button';
import Card from '@nib/card';
import { Section, Stack, Columns, Column, Tiles } from '@nib/layout';
import {
  HealthInsuranceGraphicIcon,
  LifeInsuranceGraphicIcon,
  TravelGraphicIcon,
  CommunityPartnersGraphicIcon,
} from '@nib/icons';

import metrics from '../metrics';
import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import MemberStory from '../components/MemberStory';
import WhyChooseNib from '../components/shared/WhyChooseNib';
import CallBackModuleBase from '../components/CallBackModal';
import UtilityButtons from '../components/UtilityButtons';
import { urlConstants } from '../constructs/constants';
import { ContentfulMemberStoryNode, UtilityLinkModel } from '../constructs/models';

import desktop from '../img/our-products/hero/desktop.jpg';
import desktop2x from '../img/our-products/hero/desktop@2x.jpg';
import mobile from '../img/our-products/hero/mobile.jpg';
import mobile2x from '../img/our-products/hero/mobile@2x.jpg';
import tablet from '../img/our-products/hero/tablet.jpg';
import tablet2x from '../img/our-products/hero/tablet@2x.jpg';
import { HeroPanelCopy, ReducedGapCard } from '../components/styledComponents';

const title = 'Our Products';
const description =
  'nib offers a range of health insurance options to give you peace of mind and support when you need it.';

const schema = {
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  '@id': 'WebPage',
  identifier: 'https://www.nib.co.nz/our-products',
  url: 'https://www.nib.co.nz/our-products',
  description,
  name: ['Our products | nib'],
  isPartOf: 'https://www.nib.co.nz',
};
const JSONschema = JSON.stringify(schema);

const OurProducts = (): JSX.Element => (
  <Layout>
    <div>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={description} />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <HeroPanelSection />
      <FindTheRightCoverSection />
      <WhyInsuranceIsWorthItSection />
      <WhyChooseNib left="EasyToUse" middle="SupportTools" right="OurMembers" />
      <MemberStorySection />
      <NavigationSection />
    </div>
  </Layout>
);

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title="Our products"
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    >
      <HeroPanelCopy>
        We offer a range of insurance options to give you peace of mind that you have support when
        you need it.
      </HeroPanelCopy>
    </HeroPanel>
  );
};

const FindTheRightCoverSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={4}>
        <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} align="center" component="h2">
          Find the right cover for you
        </Heading>
        <Tiles space={{ xs: 2, xl: 4 }} columns={{ sm: 1, md: 2, lg: 4 }}>
          <HealthInsuranceCover />
          <EmployerInsuranceCover />
          <LifeAndLivingInsurance />
          <TravelInsuranceCover />
        </Tiles>
      </Stack>
    </Section>
  );
};

const HealthInsuranceCover = (): JSX.Element => {
  return (
    <ReducedGapCard align="center" icon={HealthInsuranceGraphicIcon} style={{ height: '100%' }}>
      <Card.Content title="Health insurance">
        <Copy align="center">
          Make it easier to manage your health and get the care you need, when you need it.
        </Copy>
      </Card.Content>
      <Card.Footer>
        <PrimaryButton href="/compare-plans">Compare plans</PrimaryButton>
        <Link href="/health-insurance">Learn more</Link>
      </Card.Footer>
    </ReducedGapCard>
  );
};

const EmployerInsuranceCover = (): JSX.Element => {
  return (
    <ReducedGapCard align="center" icon={CommunityPartnersGraphicIcon} style={{ height: '100%' }}>
      <Card.Content title="Employer health insurance">
        <Copy align="center">
          Improve wellbeing in your workplace with tailored health cover solutions.
        </Copy>
      </Card.Content>
      <Card.Footer>
        <PrimaryButton href={urlConstants.employerInsuranceCallbackSection}>
          Arrange a call back
        </PrimaryButton>
        <Link href={urlConstants.employerInsurance}>Learn more</Link>
      </Card.Footer>
    </ReducedGapCard>
  );
};

const LifeAndLivingInsurance = (): JSX.Element => {
  return (
    <ReducedGapCard align="center" icon={LifeInsuranceGraphicIcon} style={{ height: '100%' }}>
      <Card.Content title="Life and living insurance">
        <Copy align="center">
          Protect your lifestyle if you can’t work anymore and your loved ones if you pass away.
        </Copy>
      </Card.Content>
      <Card.Footer>
        <PrimaryButton href={urlConstants.compareLifeAndLivingInsurance}>
          Cover options
        </PrimaryButton>
        <Link href={urlConstants.lifeAndLivingInsurance}>Learn more</Link>
      </Card.Footer>
    </ReducedGapCard>
  );
};

const TravelInsuranceCover = (): JSX.Element => {
  return (
    <ReducedGapCard align="center" icon={TravelGraphicIcon} style={{ height: '100%' }}>
      <Card.Content title="Travel insurance">
        <Copy align="center">
          Get the support you need in case something goes wrong on your adventures.
        </Copy>
      </Card.Content>
      <Card.Footer>
        <PrimaryButton href={urlConstants.travelInsurance}>Get a quote</PrimaryButton>
        <Link href={urlConstants.travelInsurance}>Learn more</Link>
      </Card.Footer>
    </ReducedGapCard>
  );
};

interface WhyItsWorthItContentShape {
  title: string;
  bodyText: string;
}

const WhyInsuranceIsWorthItSection = (): JSX.Element => {
  const infoShape: WhyItsWorthItContentShape[] = [
    {
      title: 'Are ready for the unexpected',
      bodyText: 'You and your family have more choice in getting treatment when you need it.',
    },
    {
      title: 'Have peace of mind',
      bodyText: 'Knowing you have cover means you can focus on getting well.',
    },
    {
      title: 'Secure your future',
      bodyText: `Your costs for approved private treatment are reduced
      so your health can get the help it needs, meaning less time off
      work and less financial worry about healthcare bills.`,
    },
  ];

  return (
    <Section role="section" background="trueGreen">
      <Stack space={7}>
        <Stack space={4}>
          <Heading size={{ sm: 4, md: 3, lg: 2 }} color="white" component="h2">
            Why insurance is worth it
          </Heading>
          <Copy measure={false} color="white">
            Sometimes things don't go as expected. But having health insurance can help us tackle
            unforeseen circumstances. We're used to insuring our cars and homes, but what about our
            health? Health is your greatest asset and should be protected to ensure you can live a
            healthier, happier life.
          </Copy>
          <Copy measure={false} color="white">
            Having health insurance means you:
          </Copy>
        </Stack>
        <Columns space={{ xs: 4, md: 6 }} collapseBelow="md">
          {infoShape.map((data, i) => {
            const { title, bodyText } = data;
            return (
              <Column flex key={i}>
                <Stack space={3}>
                  <Copy align="center" color="white">
                    <Bold>{title}</Bold>
                  </Copy>
                  <Copy small align="center" color="white">
                    {bodyText}
                  </Copy>
                </Stack>
              </Column>
            );
          })}
        </Columns>
      </Stack>
    </Section>
  );
};

const MemberStorySection = (): JSX.Element => {
  const memberStory: ContentfulMemberStoryNode = {
    title: 'Katie’s story:',
    description: {
      childMarkdownRemark: {
        html: `<p>“I had just literally finished a half marathon the weekend
        before I was diagnosed.” When fit and healthy mum Katie
        received a shock breast cancer diagnosis, her nib insurance
        eliminated one major stress from the whole situation.</p>`,
      },
    },
    videoUrl: 'https://www.youtube.com/watch?v=ukuBapicdHI&ab_channel=nibNewZealand',
  };

  return (
    <Section role="section">
      <MemberStory videoOnRight={true} memberStory={memberStory} showButton={false} />
    </Section>
  );
};

const NavigationSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const utilityLinks: UtilityLinkModel[] = [
    {
      subtitle: 'Need help joining?',
      title: 'Ask us to call you',
      onClick: () => setIsOpen(true),
    },
    {
      subtitle: 'Looking for health cover?',
      title: 'Compare plans',
      url: '/compare-plans',
    },
    {
      subtitle: 'Health insurance:',
      title: 'Learn how it works',
      url: '/health-insurance',
    },
    {
      subtitle: 'Life and living insurance',
      title: 'Learn how it works',
      url: urlConstants.lifeAndLivingInsurance,
    },
  ];

  return (
    <Section role="section" background="warmWhite60">
      <CallBackModuleBase variant="Expert" isOpen={isOpen} setOpen={setIsOpen} />
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

export default metrics({ pageName: 'our-products' })(OurProducts);
